import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Delivery – Courier information"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Delivery – Courier information`}</h1>
    <p>{`We are extremely lucky to be able to call upon the services of a small, specialist independent carrier that is contracted to our Saw Mill in the Midlands. These guys are very experienced in the distribution of timber and it means that we do not have to rely on any big national carriers and all the major problems that are associated with them.`}</p>
    <p>{`The delivery process is very simple and straight forward. Once you have placed an order, the Mill gets back in touch with us as soon as your pergola or arbour has been machined, cut and pre-drilled and is about to enter the 24hr high pressure treatment tank. This allows us to contact you to arrange a convenient day and date when you, a member of your family or a friend, can be at home to take delivery of your pergola. We will then relay this information back to the Mill so your delivery can be booked in.`}</p>
    <p>{`The driver will also have your delivery instructions, address and telephone number or mobile so that he is able to phone you 'en-route' for any last minute directions or to keep you updated as to progress in case of any traffic jams.`}</p>
    <p>{`Once he arrives at your home, he will unload the pergola or arbour and leave it where you would like it left. Given the very nature of our products, some of the pergola components (rafters, posts etc) are awkward to handle on their own. So, whilst not being very heavy in itself, the length of a cross rafter for example can be difficult to manoeuvre. .`}</p>
    <p>{`So, when our courrier arrives at your house with a delivery, any help in giving the driver a hand with the unloading of your consignment will be much appreciated and ensure a quick turnaround at the same time.`}</p>
    <p>{`Thank you very much in advance!`}</p>
    <p>{`Customer Services – Perfect Pergolas Ltd`}</p>
    <p><strong parentName="p">{`*`}{`NB - Please be aware that any discrepancies or missing items must be notified to us within 7 days of the goods being delivered`}</strong></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      